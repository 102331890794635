import axios from "axios";

import { Abstract } from './Abstract'; 
  export class InventoryMovementWarehouseUEPS extends Abstract 
  { 
  constructor(session) { 
  super('Inventory/InventoryMovementWarehouseUEPS', session) ;

this.id_movementtype = null; 

this.id_movement = null; 

this.date_mov = null; 

this.comment=null;

this.items=[];



    }  
    async getCustom() {
        let response = await axios.get(this.controller + "/getCustom", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }

      async getInventary() {
        let response = await axios.get(this.controller + "/getInventary", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }

      async getInventaryxArticle(almacen,subalmacen,sku) {
        let response = await axios.get(this.controller + "/getInventaryxArticle", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                warehouse:almacen,
                subwarehouse:subalmacen,
                article:sku
            }
        });
        return response.data;
      }

      async getproductsxwarehouse(almacen,subalmacen) {
        let response = await axios.get(this.controller + "/getInventaryxWarehouse", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                warehouse:almacen,
                subwarehouse:subalmacen,
              
            }
        });
        return response.data;
      }

    async retrieveItem() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Ingrese un id para encontrar el registro"
        let response = await axios.get(this.controller + '/item/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async Mov() {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller + '/Mov', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async transfenciaWh(almacen,subalmacen) {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller + '/transfenciaWh', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                almacen:almacen,
                subalmacen:subalmacen
            }
        });
        return response.data;
    }



    async allitem() {
     
        let response = await axios.get(this.controller + '/allitem', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async getAdminMov(FechaInicial,FechaFinal) {
        let response = await axios.get(this.controller + "/getAdminMov", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                fechainicial:FechaInicial,
                fechafinal:FechaFinal
            }
        });
        return response.data;
      }

      async getAdminMovUEPS(FechaInicial,FechaFinal) {
        let response = await axios.get(this.controller + "/getAdminMovUEPS", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                fechainicial:FechaInicial,
                fechafinal:FechaFinal
            }
        });
        return response.data;
      }

      async getReportkardex(FechaInicial,FechaFinal) {
        let response = await axios.get(this.controller + "/getReportkardex", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                fechainicial:FechaInicial,
                fechafinal:FechaFinal
            }
        });
        return response.data;
      }

 } 
 