import { Abstract } from './Abstract'; 
import axios from "axios";
  export class InventoryConfiguration extends Abstract 
  { 
  constructor(session) { 
  super('Inventory/InventoryConfiguration', session) ;

this.name = null; 

this.key_name = null; 

this.value = null; 


    }  


    async getCustom() {
      let response = await axios.get(this.controller + "/getCustom", {
          headers: {
              usuario: this.usuario,
              empresa: this.intEmpresa,
              sucursal: this.intSucursal
          }
      });
      return response.data;
    }
 } 
 
